* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.containerN {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: white;

  color: #be3455;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-weight: 600;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.35rem;
  margin: 0 0.5rem;
}

.nav li {
  margin: 0 0.5rem;
}

.nav li.active {
  margin: 0 0.5rem;
  /* height: 4em; */
  border-radius: 6px;
  text-decoration-line: underline;
  text-decoration-color: #271f30;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  /* background-color: #2b1331; */
  /* padding: 0.25rem; */
}

.nav li:hover {
  margin: 0 0.5rem;
  /* height: 4em; */
  /* padding: 0.25rem; */
  border-radius: 6px;
  text-decoration-line: underline;
  text-decoration-color: #271f30;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  /* background-color: #b9abcf; */
}

@media only screen and (max-width: 600px) {
  .nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav ul {
    flex-direction: column;
  }

  .nav li {
    margin: 0 0.5rem;
  }

  .site-title {
    margin-bottom: 0.5rem;
  }
}
